import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HeaderSection from '../components/section-header'
import BlogListSection from '../components/section-blog-list'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout>
      <HeaderSection small {...frontmatter.header} />
      <BlogListSection posts={posts} />
    </Layout>
  )
}

export const query = graphql`
  query BlogIndexPageQuery($id: String!) {
    markdownRemark(id: {eq: $id }) {
      frontmatter {
        header {
          title
          backgroundColor
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
      }
      html
    }
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "blog-post"}, published: {eq: true}}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            date(formatString: "MMMM DD, YYYY")
            description
            tags
            # teaserImage {
            #   childImageSharp {
            #     fixed(height: 225, width: 599, fit: COVER) {
            #       src
            #     }
            #   }
            # }
          }
        }
      }
    }
  }
`